<template>
    <div class="scrap">
          <van-popup v-model="showMap" position="button" closeable :close-on-click-overlay="false" :style="{ height: '100%',width:'100%',paddingTop:'20px' }" >
            <iframe id="mapPage" width="100%" height="100%" frameborder=0
                src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=WYZBZ-SEE3X-SYZ4N-7W7QQ-J55VJ-7JFB4&referer=myapp">
            </iframe>
        </van-popup>
        <div class="title">
            机动车报废申请
        </div>
        <hr>
        <div class="scrap-content">
            <van-form @submit="onSubmit" label-width="100px" label-align="right">
                <div class="scrap-content-div">
                    <van-field
                        v-model="form.carNumberPlate"
                        name="用户名"
                        label="车牌号："
                        placeholder="车牌号"
                        :rules="[{ required: true, message: '请填写车牌号' }]"
                    ></van-field>
                </div>
                <div class="scrap-content-div">
                    <van-field
                        v-model="form.vin"
                        name="车架号VIN码"
                        label="车架号VIN码："
                        placeholder="车架号VIN码"
                        :rules="[{ required: true, message: '请填车架号VIN码' }]"
                    ></van-field>
                        <van-uploader :after-read="afterRead" >
                            <van-button size="small"  native-type="button" type="info">拍照识别车架号</van-button>
                        </van-uploader>
             
                </div>
                 <van-field
                        v-model="form.vehicleBrand"
                        name="用户名"
                        label="品牌："
                        placeholder="品牌"
                        :rules="[{ required: true, message: '输入品牌' }]"
                    />
                 <van-field
                        v-model="form.vehicleModel"
                        name="用户名"
                        label="型号："
                        placeholder="型号"
                        :rules="[{ required: true, message: '输入型号' }]"
                    />
                <div>
                    <van-field
                        v-model="form.location"
                        name="用户名"
                        label="位置："
                        placeholder="位置"
                        :rules="[{ required: true, message: '输入位置' }]"
                    >
                        <template #button>
                            <van-button class="btn-p"  native-type="button" size="small" type="info" @click="isMap">地图定位</van-button>
                        </template>
                    
                    </van-field>
                       
                </div>
              
                    <van-field
                        name="用户名"
                        label="上传车辆照片（最多6张）:"
                        placeholder="取车位置"
                        :rules="[{ required: true, message: '上传照片！' }]"
                    >
                    <template #input>
                        <UploadImg ref="business_license" :images="uploader" :maxCount="6" />
                    </template>
                    </van-field>
                    <van-field
                        v-model="form.contactPersion"
                        name="用户名"
                        label="联系人姓名"
                        placeholder="联系人姓名"
                        :rules="[{ required: true, message: '输入联系人姓名' }]"
                    />
                    <van-field
                        v-model="form.contactPhone"
                        name="用户名"
                        label="联系人手机"
                        placeholder="联系人手机"
                        :rules="[{ required: true, message: '输入联系人手机' }]"
                    >
                    <template #button>
                        <van-button size="small"  native-type="button" type="info"  @click="sendMsg" v-if="!second_show">发送验证码</van-button>
                        <van-button
                            native-type="button"
                            type="info"
                            size="small"
                            class="sendmsg"
                            disabled
                            v-if="second_show"
                        >
                            <van-count-down
                            :time="60000"
                            format="ss秒"
                            style="color: #fff; padding: 0 15px"
                            @finish="finish"
                            v-if="second_show"
                            />
                             </van-button>
                    </template>
                    </van-field>
                    <van-field
                        v-model="form.code"
                        name="用户名"
                        label="手机验证码"
                        placeholder="手机验证码"
                        :maxlength="6"
                        :rules="[{ required: true, message: '输入手机验证码' }]"
                    />
                    <div style="margin: 50px 16px 16px;">
                        <van-button round block type="info" native-type="submit">马上申请报废，获取报废价格</van-button>
                    </div>
            </van-form>
        </div>
        <van-popup v-model="show" :close-on-click-overlay="false" class="loading"><van-loading  size="40px" >上传中。。。</van-loading></van-popup>
     
        <van-popup class="accomplish" v-model="content_show"  :close-on-click-overlay="false">
                <div class="accomplish-title">提交成功</div>
                <hr>
                <div class="accomplish-zi">您的报废申请已经提交成功，请保持手机畅通，我们的工作人员会尽快与您取得联系，为您提供最优质的服务。</div>
                <hr>
                <div>
                    <van-button round block type="info" @click="isConfirm">确定</van-button>
                </div>
        </van-popup>
        
    </div>

</template>
<script>

</script>

<script>
import { isShouQuans,NO1ShouQuans } from '../../utils/impower.js'
import UploadImg from "../../components/UploadImg";
import { micro_post ,is_post} from "../../Http/api";
import wx from 'weixin-js-sdk'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
    export default {
          metaInfo(){
            return {
                 title: this.$route.query.shopId == '1078' ? '移动端拆船件' :    window.localStorage.getItem('getShopName') 
            }
        },
        components: {
            UploadImg,
        },
        data() {
            return {
                 uploader:[],
                 show:false,//上传照片加载
                 showMap:false,//地图
                 second_show:false,//验证码
                 content_show:false,//提交完成
                 form:{
                    carNumberPlate:"",//车牌号
                    vehicleBrand:"",//品牌
                    vehicleModel:"",//型号
                    vin:"",//车架号
                    gps:{},//经纬度
                    location:"",//车辆位置
                    photo:'',//图片
                    contactPersion:"",//联系人
                    contactPhone:"",//联系电话
                    shopId:"",//店铺id
                    code:'',//验证吗
                 }
            }
        },
        mounted () {
            var this_ = this
            window.addEventListener('message', function(event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
                var loc = event.data;
                if (loc && loc.module == 'locationPicker') {//防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
                    this_.form.location = loc.cityname +  loc.poiaddress + loc.poiname
                    this_.form.gps = loc.latlng
                    this_.showMap = false
                }
            }, false);
        },
        created () {
            NO1ShouQuans(this)
        },
        methods: {
             getDaya(){
                 const data = {
                    url: "/index.php/BidOrder/bidNumDetail",
                    params: { shopId: this.$route.query.shopId,type:this.$route.query.type},
                    success: (res) => {
                       this.is_succ = res.data.is_succ
                       this.is_join = res.data.is_join
                    },
                    code_fun:{
                        1:(res)=>{
                            this.show = true
                        },
                    },
                };
                micro_post(data,this);
            },
         
            isConfirm(){
                this.form={}
                this.$refs.business_license.imagesUrlList = []
                this.$refs.business_license.images = []
                this.uploader = []
                this.content_show = false
                wx.closeWindow()
            },
            //获取验证码
            sendMsg() {
                let phone = this.form.contactPhone;
                ///console.log(phone);
                if (!phone) {
                    this.$toast.fail("手机号不能为空");
                    return;
                }
                const data = {
                    url: "/index.php/BidManagement/send_msg",
                    params: { phone },
                    success: (data) => {
                    this.$toast.success({
                        message: data.msg,
                        duration: 1000,
                        type: "success",
                    });
                        this.second_show = true;
                    },
                };
                micro_post(data,this);
            },
            //时间倒计时结束
            finish() {
                this.second_show = false;
            },
            //打开地图选中
            isMap(){
               this.showMap = true
            },
            //提交信息
            onSubmit() { 
                this.form.shopId = this.$route.query.shopId
                this.form.photo = this.$refs.business_license.imagesUrlList
                const data = {
                    url: "/index.php/BidManagement/applyVehicles",
                    params: this.form,
                    success: (data) => {
                        if(data.code == 0){
                            this.content_show = true
                        }

                    }
                };
                micro_post(data,this);
            },
            //上传照片
            afterRead(file){
                this.show = true
                let params = new FormData(); //创建form对象
                params.append("file", file.file);
                params.append("w", 200);
                params.append("h", 200);
                this.$http
                    .post("/index.php/index/web_upload/index", params)
                    .then((res) => {
                    if (res.data.code == 0) {
                       this.VinDiscern(res.data.data)
                    } else {

                       this.show= false
                       this.$toast.fail(res.data.msg)
                    }
                })
                .catch(()=>{
                    this.show= false
                })
            },
            //失败VIN
            async VinDiscern(img){
                try{
                    let a = encipherMent( JSON.stringify({url:img}))
                let param = new URLSearchParams();
                param.append("value",a);
                 const res = await is_post( "/index.php/index/bid_management.bid_order/apiBaiDu",param)
                    console.log(res)
                    this.form.vin = res.data
                     this.show= false
                }
                catch{
                     this.form.vin = ''
                     this.show= false
                }
                
            }
        },
    }
</script>

<style lang="scss" scoped>
.scrap{
    // padding: 10px;
    .title{
        text-align: center;
        font-size: 26px;
        margin-top: 10px;
        padding: 10px;
    }
    .scrap-content{
        .scrap-content-div{
            margin-bottom: 20px;
        }
    }
    .btn-p{
        margin: 5px;
    }
}
.van-loading{
    height: 75px !important;
    background: rgba(0,0,0,0.7);
}
.accomplish{
    width: 80%;
    font-size: 26px;
    padding: 20px;
    margin-right: 0 !important;
   
    .accomplish-title{
        text-align: center;
    }
    .accomplish-zi{
        text-indent:2em;
    }
}
</style>
